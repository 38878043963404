<template>
  <div class="tipsBox">
    <el-dialog
      title=""
      :show-close="false"
      :visible.sync="tipsVisible"
      width="1108px"
    >
      <div>
        <div class="tipsHeader">
          <h2>
            {{ isUkey ? 'Ukey获取证书信息步骤' : '签名文档获取证书信息步骤' }}
          </h2>
          <el-button type="text" @click="isUkey = !isUkey"
            ><svg-icon class="icon" icon-class="change" />{{
              isUkey ? '签名文档获取证书信息步骤' : 'Ukey获取证书信息步骤'
            }}</el-button
          >
        </div>
        <div class="tipsMain">
          <h2>{{ isUkey ? ukeyData.title : pdfData.title }}</h2>
          <p>
            {{ isUkey ? ukeyData.content : pdfData.content }}
          </p>
          <div class="certInfo clearfix" v-if="isUkey">
            <dl v-for="(item, index) in ukeyData.steps" :key="index">
              <dt>
                {{ item.text }}
              </dt>
              <dd>
                <img :src="item.img" alt="stepImg" />
              </dd>
            </dl>
          </div>
          <div class="certInfo pdfInfo clearfix" v-else>
            <dl v-for="(item, index) in pdfData.steps" :key="index">
              <dt>
                {{ item.text }}
              </dt>
              <dd>
                <img :src="item.img" alt="stepImg" />
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tipsVisible = false"
          >我知道了</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'infoTips',
  data() {
    return {
      tipsVisible: false,
      isUkey: true,
      ukeyData: {
        title: 'Ukey获取证书信息步骤',
        content:
          '使用Ukey获取证书信息只需要打开“豸信CA数字证书助手”，插入Ukey即可查看，具体步骤如下：',
        steps: [
          {
            text:
              '（1）打开“豸信数字证书助手”，插入Ukey，在“我的证书”界面点击“查看”按钮',
            img: require('@/assets/icons//service/certQuery/ukey-1.png')
          },
          {
            text: '（2）点击“详细信息”选择使用者查看CN',
            img: require('@/assets/icons//service/certQuery/ukey-2.png')
          },
          {
            text: '（3）点击“详细信息”选择序列号查看序列号',
            img: require('@/assets/icons//service/certQuery/ukey-3.png')
          }
        ]
      },
      pdfData: {
        title: 'PDF签署文档获取证书信息',
        content:
          '使用Adobe、WPS等主流PDF阅读器打开签名文档即可查看，下面为WPS查看证书的步骤（Adobe查看证书的步骤类似）：',
        steps: [
          {
            text: '（1）点击签章会弹出下图弹窗，点击“签名属性”按钮',
            img: require('@/assets/icons//service/certQuery/sign-1.png')
          },
          {
            text: '（2）点击“证书信息”按钮',
            img: require('@/assets/icons//service/certQuery/sign-2.png')
          },
          {
            text: '（3）点击“查看签名者证书”按钮',
            img: require('@/assets/icons//service/certQuery/sign-3.png')
          },
          {
            text: '（4）点击“详细信息”选择序列号查看证书序列号',
            img: require('@/assets/icons//service/certQuery/sign-4.png')
          },
          {
            text: '（5）点击“详细信息”选择使用者查看CN',
            img: require('@/assets/icons//service/certQuery/sign-5.png')
          }
        ]
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.tipsBox {
  ::v-deep .el-dialog__header {
    display: none;
  }
  ::v-deep .el-dialog__body,
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  .dialog-footer {
    padding: 24px 0;
    text-align: center;
    .el-button {
      padding: 8px 13px;
    }
  }
}
.tipsHeader {
  position: relative;
  h2 {
    width: 100%;
    height: 52px;
    background: #3b40e3;
    font-size: 16px;
    color: #ffffff;
    line-height: 52px;
    font-weight: normal;
    text-align: center;
  }
  .el-button {
    font-size: 14px;
    position: absolute;
    right: 16px;
    top: 17px;
    padding: 0;
    color: #ffffff;
  }
  .icon {
    margin-right: 4px;
  }
}
.tipsMain {
  padding: 24px 24px 0;
  h2 {
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    position: relative;
    padding-left: 11px;
    margin-bottom: 16px;
    &::before {
      position: absolute;
      width: 3px;
      height: 14px;
      content: '';
      background: $primaryColor;
      top: 2px;
      left: 0;
    }
  }
  p {
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    margin-bottom: 12px;
  }
}
.certInfo {
  padding: 24px;
  background: #f1f5ff;
  dl {
    margin-right: 21px;
    float: left;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  dt {
    font-size: 12px;
    color: #303b50;
    line-height: 16px;
    margin-bottom: 12px;
  }
  img {
    height: 338px;
  }
}
.pdfInfo {
  dl {
    margin-right: 24px;
    &:last-child,
    &:nth-last-child(2) {
      margin-top: 23px;
      img {
        height: 308px;
      }
    }
  }
  img {
    height: 246px;
  }
}
</style>
