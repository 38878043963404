<template>
  <div class="certQuery" v-loading="isCertQuery">
    <el-form
      ref="form"
      size="small"
      :model="formData"
      v-if="activeName == 'first'"
      label-width="108px"
    >
      <div class="line">
        <el-form-item
          :rules="[
            { required: true, message: '请输入证书名称', trigger: 'blur' }
          ]"
          prop="commonName"
          label="证书名称/CN"
        >
          <el-input
            maxlength="60"
            v-model.trim="formData.commonName"
            placeholder="请输入证书名称"
          ></el-input>
        </el-form-item>
      </div>
      <div class="line">
        <el-form-item
          :rules="[
            { required: true, message: '请输入证书序列号', trigger: 'blur' }
          ]"
          prop="certSn"
          label="证书序列号"
        >
          <el-input
            maxlength="25"
            v-model.trim="formData.certSn"
            onkeyup="this.value = this.value.replace(/[, ]/g,'')"
            placeholder="请输入证书序列号"
          ></el-input>
        </el-form-item>
      </div>
      <div class="helpTips">
        <el-button type="text" @click="showHelpTips"
          ><svg-icon
            class="icon"
            icon-class="question"
          />不知道证书信息？</el-button
        >
      </div>
      <div class="line btn">
        <el-button type="primary" @click="puhuaQryCert">立即查询</el-button>
      </div>
    </el-form>
    <div class="title">查询结果</div>
    <div class="result" v-if="result && result.length > 0">
      <el-table :data="result" border style="width: 100%">
        <el-table-column
          prop="signCertSn"
          label="证书序列号"
          :resizable="false"
          width="220"
        >
        </el-table-column>
        <el-table-column
          prop="commonName"
          label="证书名称/CN"
          :resizable="false"
          width="320"
        >
        </el-table-column>
        <el-table-column
          prop="issuer"
          label="颁发者"
          :resizable="false"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          prop="certEndTime"
          label="有效期"
          :resizable="false"
          min-width="200"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.certStartTime + '至' + scope.row.certEndTime
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="证书状态"
          :resizable="false"
          width="120"
        >
          <template slot-scope="scope">
            <span
              v-if="
                new Date(scope.row.certEndTime.replace('-', '/')).getTime() >
                  new Date().getTime()
              "
              :class="{ err: scope.row.status === -1 }"
              class="state"
              >{{ scope.row.status === -1 ? '已注销' : '正常' }}</span
            >
            <span v-else class="err state">已过期</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else-if="result && result.length == 0" class="resultErr">
      <div class="tt">无法查询到相关数字证书信息</div>
      <div>可能原因：</div>
      <div>1. 查询信息输入错误，请检查是否输入有误。</div>
      <div>
        2. 该数字证书不是由豸信CA颁发，请确认证书的颁发机构是否为豸信CA。
      </div>
      <!-- <div>3. 如确定该数字证书由豸信CA颁发，可以选择上传带有豸信证书的PDF文件进行<router-link :to="{name:'fileVerify'}">文档验证</router-link>，即可查询到证书信息。</div> -->
    </div>
    <infoTips ref="infoTips" />
  </div>
</template>

<script>
import infoTips from './infoTips'
export default {
  data() {
    return {
      formData: { commonName: '', certSn: '' },
      result: null,
      isCertQuery: false,
      activeName: 'first'
    }
  },
  components: {
    infoTips
  },
  methods: {
    handleClick() {
      this.formData.commonName = ''
      this.formData.certSn = ''
    },
    puhuaQryCert() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$tencentCaptcha((val) => {
            val['cnOrName'] = this.formData.commonName
            val['snOrNo'] = this.formData.certSn
            val['mode'] = this.activeName === 'first' ? 0 : 1
            this.isCertQuery = true
            this.$api
              .puhuaQryCert(val)
              .then((res) => {
                this.isCertQuery = false
                this.result = res.data
              })
              .catch(() => {
                this.isCertQuery = false
              })
          })
        }
      })
    },
    showHelpTips() {
      this.$refs.infoTips.tipsVisible = true
    }
  },
  watch: {
    formData: {
      deep: true, //true为进行深度监听,false为不进行深度监听
      handler() {
        this.result = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.certQuery {
  min-height: calc(100vh - 108px);
  background: #ffffff;
  border: 1px solid #dee2e8;
  padding: 70px 24px;
  margin-top: 14px;
  ::v-deep.el-tabs__nav-wrap::after {
    display: none;
  }
  ::v-deep.el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
  }
  ::v-deep.el-form {
    margin-bottom: 64px;
    .line {
      width: 100%;
      display: flex;
      justify-content: center;
      &.btn {
        padding-top: 56px;
        .el-button {
          width: 91px;
          height: 32px;
          padding: 0;
          background: #1c67f3;
          border-radius: 2px;
          span {
            display: inline-block;
            line-height: 32px;
            font-size: 14px;
          }
        }
      }
    }
    .el-form-item {
      width: 700px;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 16px;
      }
    }
  }
  .helpTips {
    width: 700px;
    margin: 0 auto;
    text-align: right;
    .icon {
      margin-right: 4px;
      margin-top: 1px;
    }
    .el-button {
      padding: 0;
    }
  }
  .title {
    font-size: 14px;
    font-weight: bold;
    color: #303b50;
    line-height: 20px;
    position: relative;
    padding-left: 12px;
    margin-bottom: 24px;
    &::before {
      position: absolute;
      width: 3px;
      height: 16px;
      content: '';
      background: $primaryColor;
      top: 2px;
      left: 0;
    }
  }
  .result {
    .state {
      width: 98px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #eff9eb;
      border-radius: 4px;
      border: 1px solid #d4f0c8;
      display: inline-block;
      font-size: 14px;
      color: #52c11b;
      &.err {
        background: #fff7f7;
        border-radius: 4px;
        border: 1px solid #ffd2ce;
        color: #ed5448;
      }
    }
    ::v-deep .el-table__header,
    ::v-deep .el-table__body {
      width: 100% !important;
    }
  }
  .resultErr {
    background: #f3f6fd;
    border-radius: 2px;
    border: 1px solid #e6e8ea;
    padding: 40px 60px;
    color: #333333;
    .tt {
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      margin-bottom: 40px;
    }
    & > div {
      margin-bottom: 24px;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
