var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isCertQuery),expression:"isCertQuery"}],staticClass:"certQuery"},[(_vm.activeName == 'first')?_c('el-form',{ref:"form",attrs:{"size":"small","model":_vm.formData,"label-width":"108px"}},[_c('div',{staticClass:"line"},[_c('el-form-item',{attrs:{"rules":[
          { required: true, message: '请输入证书名称', trigger: 'blur' }
        ],"prop":"commonName","label":"证书名称/CN"}},[_c('el-input',{attrs:{"maxlength":"60","placeholder":"请输入证书名称"},model:{value:(_vm.formData.commonName),callback:function ($$v) {_vm.$set(_vm.formData, "commonName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.commonName"}})],1)],1),_c('div',{staticClass:"line"},[_c('el-form-item',{attrs:{"rules":[
          { required: true, message: '请输入证书序列号', trigger: 'blur' }
        ],"prop":"certSn","label":"证书序列号"}},[_c('el-input',{attrs:{"maxlength":"25","onkeyup":"this.value = this.value.replace(/[, ]/g,'')","placeholder":"请输入证书序列号"},model:{value:(_vm.formData.certSn),callback:function ($$v) {_vm.$set(_vm.formData, "certSn", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.certSn"}})],1)],1),_c('div',{staticClass:"helpTips"},[_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.showHelpTips}},[_c('svg-icon',{staticClass:"icon",attrs:{"icon-class":"question"}}),_vm._v("不知道证书信息？")],1)],1),_c('div',{staticClass:"line btn"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.puhuaQryCert}},[_vm._v("立即查询")])],1)]):_vm._e(),_c('div',{staticClass:"title"},[_vm._v("查询结果")]),(_vm.result && _vm.result.length > 0)?_c('div',{staticClass:"result"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.result,"border":""}},[_c('el-table-column',{attrs:{"prop":"signCertSn","label":"证书序列号","resizable":false,"width":"220"}}),_c('el-table-column',{attrs:{"prop":"commonName","label":"证书名称/CN","resizable":false,"width":"320"}}),_c('el-table-column',{attrs:{"prop":"issuer","label":"颁发者","resizable":false,"min-width":"200"}}),_c('el-table-column',{attrs:{"prop":"certEndTime","label":"有效期","resizable":false,"min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.certStartTime + '至' + scope.row.certEndTime))])]}}],null,false,108234874)}),_c('el-table-column',{attrs:{"prop":"status","label":"证书状态","resizable":false,"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              new Date(scope.row.certEndTime.replace('-', '/')).getTime() >
                new Date().getTime()
            )?_c('span',{staticClass:"state",class:{ err: scope.row.status === -1 }},[_vm._v(_vm._s(scope.row.status === -1 ? '已注销' : '正常'))]):_c('span',{staticClass:"err state"},[_vm._v("已过期")])]}}],null,false,705692184)})],1)],1):(_vm.result && _vm.result.length == 0)?_c('div',{staticClass:"resultErr"},[_c('div',{staticClass:"tt"},[_vm._v("无法查询到相关数字证书信息")]),_c('div',[_vm._v("可能原因：")]),_c('div',[_vm._v("1. 查询信息输入错误，请检查是否输入有误。")]),_c('div',[_vm._v(" 2. 该数字证书不是由豸信CA颁发，请确认证书的颁发机构是否为豸信CA。 ")])]):_vm._e(),_c('infoTips',{ref:"infoTips"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }